<template>
    <button
        @click="$emit('click-submit')"
        type="submit"
        :disabled="btnDisabled"
        :class="`d-flex align-items-center btn btn-sm ${btnClass}`"
        :form="form">
        <span v-if="btnDisabled" class="spinner-border spinner-border-sm mr-2"></span>{{ text }}
    </button>
</template>

<script>
export default {
    name: 'SubmitButton',
    props: {
        btnDisabled: {
            type: Boolean,
            default: false
        },
        text: {
            type: String,
            default: 'Submit'
        },
        btnClass: {
            type: String,
            default: 'btn-primary'
        },
        form: String
    }
};
</script>

