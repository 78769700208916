import axios from 'axios';

export const getItem = id => {
    return axios.get(`items/${id}/`);
}

export const addItem = item => {
    return axios.post('items/', item);
}

export const updateItem = (id, data) => {
    return axios.patch(`items/${id}/`, data);
}

export const addItemFieldData = async (fields, id) => {
    for (const field of fields) {
        await axios.post('/field_data/', {
            data: field.value,
            field: field.id,
            item: id
        });
    }
}
